import React from "react";
import Layout from "../components/layout/index";
import Seo from "../components/seo";

import "../scss/404.scss";

const NotFoundPage = () => {
	return (
		<Layout>
			<Seo title="Page inexistante" />
			<div className="nonexistent-page">
				<div className="nonexistent-page-text">
					<h1 className="nonexistent-page-title">OUPS ...</h1>
					<h2 className="nonexistent-page-subtitle">cette page n'existe pas !</h2>
				</div>
				<p>🤷‍♀️</p>
			</div>
		</Layout>
	);
};

export default NotFoundPage;
